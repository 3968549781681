import React from 'react'

function Footer() {
  return (
    <div className='footer-container'>
      <h3>marcinoles87@gmail.com</h3>
    </div>
  )
}

export default Footer