import React from 'react'

function Energylandia() {
  return (
    <div>Energylandia
      <p>Trip to Energylandia soon......</p>
    </div>
  )
}

export default Energylandia